<template>
  <div id="app">
    <div class="image" ref="printMe">
      <img src="./assets/black.png" class="logo"/>
      <div class="placar">
        
        <div class="escudoHome " v-if="homeObject.length > 0">
          <img :src="times[homeObject].img" />
        </div>
        <div class="escudoAway" v-if="awayObject.length > 0">
          <img :src="times[awayObject].img"/>
        </div>
        <span class="siglaHome" v-if="homeObject.length > 0">{{times[homeObject].acronym}}</span>
        <span class="cartoesHome" v-if="home.card.length > 0">
          <span v-for="i in Number(home.card)" :key="i"></span>
        </span>
        <span class="cartoesAway" v-if="away.card.length > 0">
          <span v-for="i in Number(away.card)" :key="i"></span>
        </span>
        <span class="siglaAway" v-if="awayObject.length > 0">{{times[awayObject].acronym}}</span>
        <div class="local">
          <img src="./assets/catarinense.png" class="logocampeonato" />
          <span class="hashtag" v-if="hashtag.length > 0">{{hashtag}}</span>
          <div class="placa">
            <span>{{home.placar}}</span>
            <span>{{away.placar}}</span>
          </div>
        </div>
        <span class="tempo"><span>{{tempo}}</span></span>
        <span class="acrescimo" v-if="acrescimo > 0"><span>+ {{acrescimo}}</span></span>
      </div>
    </div>
    <div class="form">
      <div>
        <h2>Dados gerais</h2>
         <label>
            <span>Acréscimo</span>
            <input type="number" v-model="acrescimo" />
        </label>
        <label>
            <span>Hashtag de chamada</span>
            <input type="text" v-model="hashtag" />
        </label>
        <label>
          <span>Etapa do jogo</span>
          <input type="text" v-model="tempo" />
        </label>
      </div>
      <div>
        <h2>Da Casa</h2>
         <label>
          <span>Equipe</span>
          <select @change="handleTeamHome($event)">
            <option v-for="(h, index) in times" :key="h.slug + index" :value="index">{{h.name}}</option>
          </select>
        </label>
        <label>
          <span>Placar</span>
          <input type="number" v-model="home.placar" />
        </label>
        <label>
          <span>Cartões vermelhos</span>
          <input type="number" v-model="home.card" />
        </label>
      </div>
      
      <div>
        <h2>Visitante</h2>
        <label>
          <span>Equipe</span>
          <select @change="handleTeamAway($event)">
            <option v-for="(t, index) in times" :key="t.slug + index" :value="index">{{t.name}}</option>
          </select>
        </label>
        <label>
          <span>Placar</span>
          <input type="number" v-model="away.placar" />
        </label>
        <label>
          <span>Cartões vermelhos</span>
          <input type="number" v-model="away.card" />
        </label>
      </div>
      <button @click="printMe()">GERAR IMAGEM</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      Output: null,
      times: [],
      hashtag: "#CATARINENSESERIEB",
      tempo: "1T",
      acrescimo: 0,
      homeObject: {
        img: ''
      },
      awayObject: {
        img: ''
      },
      home: {
        placar: 0,
        card: 0
      },
      away: {
        placar: 0,
        card: 0
      }
    }
  },
  mounted() {
    this.axios.get('https://api.fcftv.com.br/api/v1/teams?championship_id=1', { 'headers': { "Access-Control-Allow-Origin": "*"} }).then((response) => {
        this.times = response.data.data.data
    })
  },
  methods: {
    handleTeamAway(e) {
      this.awayObject = e.target.value
    },
    handleTeamHome(e) {
      this.homeObject = e.target.value
    },
    async printMe() {
      const el = this.$refs.printMe
      const options = {
        type: 'dataURL',
        useCORS: true,
        width: 1920,
        height: 1080,
        backgroundColor: null
      }

      try {
        this.Output = await this.$html2canvas(el, options, { 'headers': { "Access-Control-Allow-Origin": "*"}})
        const a = document.createElement('a')
        a.href = this.Output
        a.download = 'placar.png'
        a.click()
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style style="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600;700&display=swap');
html {
  width: 1920px;
  margin: 0 auto;
  display: block;
}
body {
  margin: 0;
  box-sizing: border-box;
  padding:0;
  background: url('assets/bg.jpeg') no-repeat center center;
}
h2 {
  color:black
}
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 1920px;
  height:1080px;
  position:relative;
}
.logo {
  position:absolute;
  right:108px;
  top: 68px;
  width:199px
}
#image {
  width: 1920px;
  height:1080px;
  display: flex;
}
.local {
  width: 468px;
  height: 41px;
  position: relative;
}
.logocampeonato {
  height: 60px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -65px;
}
.form {
  display: flex;
  max-width: 800px;
  flex-direction: row;
  position: absolute;
  left:3%;
  transform: translateY(-50%);
  top:50%;
  padding: 30px;
  background: rgba(255,255,255,.9)
}
.form div {
  width: 33%;
  padding:15px;
}
.form div label {
  width: 100%;
  display: block;
  color: black;
  font-size: 14px;
  font-weight:bold;
  margin-bottom: 15px;
}
.form div label input {
  background: white;
  box-sizing: border-box;
  color: #333;
  display: block;
  width: 100%;
  padding:10px;
  border:1px solid #f0f0f0;
  font-size: 14px;
  height: 40px;
}
.form div label select {
  background: white;
  color: #333;
  display: block;
  width: 100%;
  border:1px solid #f0f0f0;
  padding:10px;
  font-size: 14px;
  height: 40px;
}
.placar {
  position: absolute;
  left:108px;
  top: 78px;
  background: white; 
  width: 655px;
  height: 41px;
  border-radius: 50px 50px 50px 50px;

}
.escudoHome {
  height: 78px;
  position: absolute;
  left: 5px;
  top: 45%;
  z-index: 2;
  transform: translateY(-50%);
}
.escudoHome img {
  height: 78px;
}
.escudoAway {
  height: 78px;
  position: absolute;
  left: 382px;
  z-index: 2;
  top: 45%;
  transform: translateY(-50%);
}
.escudoAway img {
  height: 78px;
}
.siglaHome {
  position: absolute;
  left: 76px;
  top: 50%;
  width: 107px;
  text-align: center;
  transform: translateY(-58%);
  font-size: 33px;
  color: black;
  line-height:110%;
  font-weight: bold;
}
.siglaAway {
  position: absolute;
  left: 284px;
  width: 107px;
  text-align: center;
  top: 50%;
  transform: translateY(-58%);
  font-size: 33px;
  color: black;
  line-height:100%;
  font-weight: bold;
}
.tempo {
  background: black;
  width: 187px;
  height:42px;
  display: flex;
  top:0;
  right:0;
  position: absolute;
  border-radius: 0 24px 24px 0;
  align-items: center;
}
.tempo span {
  font-size: 33px;
  color:white;
  font-weight: bold;
  width: 62px;
  text-align: center;
  border-right:1px solid #fff;
  height: 41px;
  line-height:105%;
  letter-spacing: 10%;
}
.acrescimo {
  height:42px;
  display: flex;
  top:0;
  right:-70px;
  position: absolute;
  align-items: center;
}
.acrescimo span {
  font-size: 34px;
  color:white;
  font-weight: bold;
  width: 62px;
  text-align: center;
  height: 41px;
  line-height:110%;
  letter-spacing: 10%;
}
.hashtag {
  min-width:270px;
  padding: 0 5px;
  height: 30px;
  font-size: 20px;
  color: white;
  background: #E9202A;
  display: block;
  position: absolute;
  left: 50%;
  bottom: -30px;
  font-weight: bold;
  text-transform: uppercase;
  transform:translateX(-50%)

}
.placa {
  width:101px;
  height: 41px;
  background: black;
  display: flex; 
  position: absolute;
  left:50%;
  transform: translateX(-50%);

  flex-direction: row;
  justify-items: center;
  align-items: center;
}
.placa span {
  width:50%;
  height: 100%;
  text-align: center;
  color:white;
  line-height:110%;
  font-size: 34px;
  font-weight: 600;
}
.placa span:first-child{
  border-right:1px solid white;
}
.cartoesHome {
  position: absolute;
  right: 470px;
  top:-18px;
}
.cartoesHome span {
  width: 10px;
  height: 15px;
  display: inline-block;
  margin-right: 10px;
  background: rgb(247,3,3);
  background: linear-gradient(9deg, rgba(247,3,3,1) 0%, rgba(140,0,0,1) 100%);
}
.cartoesAway {
  position: absolute;
  left: 300px;
  top:-18px;
}
.cartoesAway span {
  width: 10px;
  height: 15px;
  display: inline-block;
  margin-right: 10px;
  background: rgb(247,3,3);
  background: linear-gradient(9deg, rgba(247,3,3,1) 0%, rgba(140,0,0,1) 100%);
}
button {
  background:#E9202A;
  color: white;
  border: 0;
  margin-left: 30px;
  cursor: pointer;
}
</style>
